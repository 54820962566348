/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import jwt_decode from "jwt-decode";
import {Cookies} from 'react-cookie';
import {connect, useDispatch} from 'react-redux';
import {getProfile} from '../state/actions/login/loginAction';
import Nav from "../components/nav"
import "../css/bootstrap.min.css"
import "../css/styles.css"
import "../css/icomoon.css"
import { navigate } from "gatsby";


const Layout = ({ children, userDetails, title }) => {

  const dispatch = useDispatch();

  let cookies = new Cookies();

  if(cookies.get('token')!==undefined && !userDetails){
    let decoded = jwt_decode(cookies.get('token'));
    dispatch(getProfile(decoded.id));
  }else if (cookies.get('token')!==undefined){
    //navigate('/dashboard');
  }else if(cookies.get('idToken')===undefined){
    const isBrowser = typeof window !== "undefined";
    if(isBrowser) navigate('/');
  }

  // React.useEffect(()=>{
  //   if(!userDetails){
  //     getProfile();
  //   }
  // },[userDetails])

  return (
    <div className="page-wrapper animated fadeInLeft">
    <>
      {(cookies.get('token') || cookies.get('idToken')) && (<Nav user={userDetails} title={title} />)}
      {children}
    </>
    </div>
  )
}

export default connect(state=>({userDetails:state.loginReducer.authenticate.user}),null)(Layout);
