import React from 'react'
import { Cookies } from 'react-cookie';
import { useDispatch} from 'react-redux';
import { loginCheck } from "../../state/actions/login/loginAction"

const Student = props => {

  const dispatch = useDispatch()

  async function setProfile(profile){
    let cookies = new Cookies();
    let token = cookies.get('idToken');
    let userAuth = { token: token, profile: profile.id}
    dispatch(loginCheck(userAuth))
    props.setShowProfiles(false);
  }
  
  if(!props.user){
    props.user = {};
  }

  return (
    <div className='teacher-card card mb-0' style={{display:"inline-block"}} onClick={()=>{setProfile(props.user.user_id)}}>
          <span className="avatar mx-auto mb-2" style={{float:"left"}}>
            {!props.user.user_id.photo && <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 10 }} />}
            {props.user.user_id.photo && <img src={props.user.user_id.photo} width="50" height="50"/>}
          </span>
          <h6 style={{width:"230px",marginTop:'10px',marginLeft:"20px","paddingLeft":"20px"}}><strong>{props.user.user_id.firstname} {props.user.user_id.lastname}</strong></h6>
          <p className="desc mb-2" style={{width:"150px",marginLeft:"20px","textAlign":""}}>{props.user.user_id.email}</p>
    </div>
  )
}

export default Student
