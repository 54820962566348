import React, {useEffect, useState} from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Teacher from "../components/teacher/teacher-component"
import { getMethod } from '../helper/api';


const ITEM_HEIGHT = 48;

const Tutor = ({location}) => {

    if(!location.state){
         location.state = {subject:{},faculties:{}};
    }
    
    //console.log(location.state);

    return (
        <Layout title="Book a Lesson">
            <Seo title="Level" />
            <div className="container">
                <h5>Select a Tutor</h5>
                {location.state.tutors &&
                    location.state.tutors.map(i => (
                        <Teacher teacher={i.faculty} key={i} select={true}  />
                    ))}
            </div>        
        </Layout>
    );
}

export default Tutor;