module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase-app/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"121 Islam","short_name":"121 Islam","start_url":"/dashboard","background_color":"#f8fcff","theme_color":"#dbefff","display":"standalone","icon":"src/images/121islamonline.png","scope":"https://121islamonline.stsltd.in","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a1e9e7da307ef9b10958e10e0c9b6b6e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase-messaging/gatsby-browser.js'),
      options: {"plugins":[],"config":{"apiKey":"AIzaSyAdPuQIK-hnEz09vxYxGLYT5ypOMnLM3uM","authDomain":"wide-decoder-255614.firebaseapp.com","projectId":"wide-decoder-255614","storageBucket":"wide-decoder-255614.appspot.com","messagingSenderId":"771290168402","appId":"1:771290168402:web:4c5c7e7de0185ef292738b","measurementId":"G-HVFBSCELDQ"},"firebaseVersion":"8.10.1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"/var/www/preprod/121-islam-frontend/src/sw.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
