import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from "redux-thunk";
import { Provider } from 'react-redux';
import rootReducer from './reducerManger';

let middlewares = [thunk];
//const composeEnhancers = typeof window !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
const composeEnhancers = compose;

//let hostname =  typeof window !== 'undefined' ? window.location.hostname : '';
const createNewStore = () => createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);


export default ({element}) => (
  
    <Provider store={createNewStore()}>{(element)}</Provider>
);
