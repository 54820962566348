import React, { useState} from 'react';
import TeacherComponet from '../components/teacher/teacher-component';
import { useDispatch, connect } from 'react-redux';
import { Link } from 'gatsby';
import { setBookingDetails } from '../state/actions/booking/bookingAction';

const Teacher = ( props ) =>{

    const dispatch = useDispatch();

    const [teacher, setTeacher] = useState(""); 

    const handleChange = (teacher) => {
        setTeacher(teacher);
        //dispatch(setBookingDetails({'teacher':teacher}));
      };

    const onSubmit=()=>{
        //dispatch(setBookingDetails({'teacher':teacher}));
    }

    return (
    <div>
      <header className='bar d-flex'>
        <Link to='/book-a-lesson'>
          <i
            className='col-auto icon icon-Left p-0'
            style={{ fontSize: 40, fontWeight: 500 }}
          />
        </Link>
        <h5>Available Teachers</h5>
        <button className='bar-item button float-right ml-auto'>
          <i className='icon icon-Sort' />
        </button>
      </header>
      <div className='container animated fadeInLeft'>
        <div className='card-white border-radius class-list mx-sm'>
       <TeacherComponet {...props} setTeacher={handleChange}/> 
       </div>
       <button  onClick={()=>onSubmit()} >test</button>
       </div>
    </div>)
}



export default connect(
    state => ({ bookingDetails: state.bookingReducer}),
    null
  )(Teacher)
  