import React, { useState } from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from '../components/dashboard/class'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { postMethod, putMethod } from '../helper/api';
import { navigate } from "gatsby";
import { notify } from 'react-notify-toast';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';

const Dispute = ({ location , user}) => {

    const getChecked=(value)=>{
        return reasons.filter(function (val) {
             return (val === value);
           }).length > 0
       }
   
    const [dispute, setDispute] = useState(location.state?.booking.dispute && location.state?.booking.dispute ? location.state?.booking.dispute : '');
    const disputeArray = dispute?.reason ?  dispute?.reason.split(','): [];
    const [reasons,setReasons] = useState(disputeArray);
    const [reason1, setReason1] = useState(getChecked("Lesson was incomplete due to teacher"));
    const [reason2, setReason2] = useState(getChecked("Lesson was incomplete due to me"));
    const [reason3, setReason3] = useState(getChecked("I did not have this lesson"));
    const [reason4, setReason4] = useState(getChecked("Teacher was not professional"));
    const [reason5, setReason5] = useState(getChecked("Background was noisy"));
    const [remarks, setRemarks] = useState(dispute && dispute.remarks ?  dispute.remarks : '' );

    const [response, setResponse] = useState(dispute && dispute.response ?  dispute.response : '' );

    const [teacherResponse, setTeacherResponse] = useState('');
   
    

    function confirm() {
        confirmAlert({
            title: 'Cancel Booking',
            message: 'Are you sure to do this?',
            buttons: [
                {
                    label: 'Cancel',
                    //className: 'bg-red'
                },
                {
                    label: 'Keep Lesson',
                    onClick: () => postFeedback(false),
                    className: 'bg-blue'
                },
                {
                    label: 'Donate',
                    onClick: () => postFeedback(true),
                    className: 'bg-green'
                },
            ]
        });
    }

    async function postFeedback() {
        //let cancelledBy = location.state.booking.booked_by.id;
       
        if(dispute && dispute.id){

           
        //console.log("reasonsreasonsreasons",reasons)
        let resp = await putMethod(`/disputes/${dispute.id}`, { response:teacherResponse});
        //let resp2 = await postMethod('/cancelbooking',{id:location.state.booking.id,donate: donate });
        if (resp.error) {
            notify.show("Error: ", resp.error);
        } else {
            setDispute(resp.data);
            setResponse(resp.data.response);
            setTimeout(() => notify.show("Dispute responsed successfully", "success"), 100);
            //navigate('/dashboard')
        }

        }else{
            // let resp = await postMethod('/disputes', { booking: location.state.booking.id, reason: reasons.join(','), remarks: remarks, raised_by: user.id,booking_id:location.state.booking.id});
            // //let resp2 = await postMethod('/cancelbooking',{id:location.state.booking.id,donate: donate });
            // if (resp.error) {
            //     notify.show("Error: ", resp.error);
            // } else {
            //     setTimeout(() => notify.show("Dispute raised successfully", "success"), 100);
            //     navigate('/dashboard')
            // }

        }
        }

    if (!location.state) {
        location.state = {}
    }



  const setChecked =(value,event)=>{
    if(!reasons.includes(value)){         
        reasons.push(value);              
    }else{
        reasons.splice(reasons.indexOf(value), 1); 
    }
    setReasons(reasons);


    switch (value) {
        case "Lesson was incomplete due to teacher":
            setReason1(event.target.checked || false);
            break;
        case "Lesson was incomplete due to me":
            setReason2(event.target.checked || false);
            break;
        case "I did not have this lesson":
            setReason3(event.target.checked || false);
            break;
        case "Teacher was not professional":
            setReason4(event.target.checked || false);
            break;
        case "Background was noisy":
            setReason5(event.target.checked || false);
            break;
    
        default:
            break;
    }

   


  }
  
   

    return (
        <Layout title="Dispute">
            <Seo title="Dispute" />
            <div className="has-fixed-btn">
                <div className="container">
                    <div className="date-card-section">
                        <Booking booking={location.state.booking} />
                    </div>
                    <h5>Reason</h5>
                    <form className="cancelClass mb-3">

                        {reason1 && (
                        <div className="form-check">
                            <input className="form-check-input" disabled="disabled" type="checkbox" value="Lesson was incomplete due to teacher"  checked={reason1} onClick={event => {  setChecked("Lesson was incomplete due to teacher",event) }} />
                            <label className="form-check-label">Lesson was incomplete due to teacher</label>
                        </div>)}
                        {reason2 && (
                        <div className="form-check">
                            <input className="form-check-input" disabled="disabled" type="checkbox" value="Lesson was incomplete due to me"  checked={reason2} onClick={event => { setChecked("Lesson was incomplete due to me",event)}} />
                            <label className="form-check-label">Lesson was incomplete due to me</label>
                        </div>)}
                        {reason3 && (
                        <div className="form-check">
                            <input className="form-check-input" disabled="disabled" type="checkbox" value="I did not have this lesson"  checked={reason3} onClick={event => { setChecked("I did not have this lesson",event) }} />
                            <label className="form-check-label">I did not have this lesson</label>
                        </div>)}
                        
                        {reason4 && (<div className="form-check">
                            <input className="form-check-input" disabled="disabled" type="checkbox" value="Teacher was not professional" checked={reason4} onClick={event => { setChecked("Teacher was not professional",event) }} />
                            <label className="form-check-label">Teacher was not professional</label>
                        </div>)}
                        
                        {reason5 && (<div className="form-check">
                            <input className="form-check-input" disabled="disabled" type="checkbox" value="Background was noisy" checked={reason5} onClick={event => { setChecked("Background was noisy",event)}} />
                            <label className="form-check-label">Background was noisy</label>
                        </div>)}
                    </form>
                    <h5>Remarks</h5>
                    <div className="card card-info">{remarks}<br/><br/>{moment(dispute.created_at).format('DD/MM/YYYY HH:mm')}</div>

                    {dispute?.raised_by?.id!==user?.id && <>
                    <h5>Reply</h5>
                    <textarea className="form-control" rows="3" cols="45" name="response" placeholder="Enter your response" value={response!==''?response:teacherResponse}  onChange={event => setTeacherResponse(event.target.value)}></textarea>
                    </>}

                    {dispute.is_approved!==null && ( 
                        <>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <h5  style={{color:'green'}}>Approved: {dispute.is_approved?'Yes':'No'}</h5>
                            <div>{dispute.approved_comments}</div>
                        </>
                    )}    
                    
                    {dispute.is_rejected!==null && (
                        <>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>

                            <h5 style={{color:'red'}}>Rejected: {dispute.is_rejected?'Yes':'No'}</h5>
                            <div>{dispute.rejected_comments}</div>
                        </>
                    )}
                </div>
                {response=='' && dispute?.raised_by?.id!==user?.id && <div className="fixed-btn-wrapper">
                    {dispute && Object.keys(dispute).length > 0  && response=='' ?  ( 
                        <div className="btn btn-primary w-100" onClick={() => postFeedback()}>Post Response</div>
                    ) :( 
                        <div className="btn btn-primary w-100" onClick={() => postFeedback()}>Submit</div>
                    )}
                </div>}    
            </div>     
        </Layout>
    );
}

export default connect(state => ({ bookings: state.userReducer.bookings, user: state.loginReducer.authenticate.user }), null)(Dispute);
