import React from 'react';
import {useState} from 'react'
import Booking from './class'


const Today = (props) => {
    const [showPending, setShowPending] = useState(false);

    return (<>
        
    </>);
}

export default Today;