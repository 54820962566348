import React, { useEffect, useState, useMemo } from 'react';
import TeacherComponet from '../components/teacher/teacher-component';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Link } from 'gatsby';
import { setBookingDetails } from '../state/actions/booking/bookingAction';
import MyClasses from '../components/dashboard/my-classes'
import moment from 'moment'

const ConfirmBooking = ( {bookingDetails},props ) =>{

    const dispatch = useDispatch();


    const onSubmit = () => {
        //console.log("final-data",bookingDetails.bookingObject)
      }

    return (
    <div>
      <header className='bar d-flex'>
        <Link to='/book-a-lesson'>
          <i
            className='col-auto icon icon-Left p-0'
            style={{ fontSize: 40, fontWeight: 500 }}
          />
        </Link>
        <h5>Confirmation</h5>
        
      </header>
      <div className='container animated fadeInLeft'>
       <div class="row m-sm">
        {/* <div class="date-month col-auto pl-0"><p>{bookingDetails.bookingObject.date}</p></div> */}
    <div class="date-month col-auto pl-0"><p>{moment(bookingDetails.bookingObject.date.startDate).format('MMM')}</p>{moment(bookingDetails.bookingObject.date.startDate).format('DD')}</div>
        <MyClasses {...bookingDetails.bookingObject}/>
       </div>
       <div class='row m-sm'>
          <div class='btn btn-lg btn-blue my-lg' onClick={() => onSubmit()} >
            <span>Book</span>
          </div>
        </div>
       </div>
    </div>)
}



export default connect(
    state => ({ bookingDetails: state.bookingReducer}),
    null
  )(ConfirmBooking)
  