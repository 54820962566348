
import {BOOKING_TYPES} from '../../types/BookingType'

export const setSubject =(subject)=>dispatch =>{
    dispatch({
        type:BOOKING_TYPES.SET_SUBJECT,
        payload:subject
    })
}

export const setBookingDetails =(bookingDetails)=>dispatch =>{
    //console.log("sfsfsfsdf",bookingDetails)
    dispatch({
        type:BOOKING_TYPES.BOOKING_DETAILS,
        payload:bookingDetails
    })
}

