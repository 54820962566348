import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { getMethod } from '../../helper/api';

const Teacher = props => {

  const [rating, setRating] = useState(0);
  function showTeachers(){
    navigate('/booking-teacher',{state:{subject:props.subject, level: props.level, tutors: props.tutors}});
  }

  function selectTeacher(id){
    window.localStorage.setItem('selectedTeacher',id);
    navigate(-1)
  }

  if(!props.teacher){
    props.teacher = {};
  }

  useEffect(()=>{
    async function getrating(){
      let rating = await getMethod('/tutor/rating',"",{id:props.teacher.id});
      //console.log(rating);
      if(rating.data[0][0]!==undefined)
        setRating(rating.data[0][0].rating);
    }

    getrating();
  },[])

  return (
    <div className='teacher-card text-center card mb-0' onClick={()=>{if(props.select) selectTeacher(props.teacher.id)}}>
          <div className="avatar mx-auto mb-2">
            {!props.teacher.photo && <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />}
            {props.teacher.photo && <img src={props.teacher.photo} width="50" height="50" />}
          </div>
          <h6><strong>{props.teacher.firstname} {props.teacher.lastname}</strong></h6>
          <p className="desc mb-2">{props.teacher.position} Tutor</p>
          <div className="d-flex justify-content-center">
          {[...Array(Math.ceil(rating))].map((e,i)=>
                                <i className="icon icon-Star_Fill" key={i}></i>
                            )}
            {rating > 0 && <span className="h6 mb-0"><strong>{rating}</strong></span>}
          </div>  
        {/*{!props.select && (<i
            className='icon icon-Right ml-auto a-self-center'
            style={{ fontSize: 40, cursor: 'pointer' }}
            onClick={()=>showTeachers()}
        />)}*/}
    </div>
  )
}

export default Teacher
