import React, {useState} from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from '../components/dashboard/class'



const MyClass = ({ bookings }) => {

    const [showPending, setShowPending] = useState(true);
    return (
        <Layout title="My Classes">
            <Seo title="Home" />
            <div className="container animated fadeInLeft" >
                <div className="row m-sm border-bottom myClass">
                    <div className={showPending?"cursor-point col-6 py-sm center txt  active":"cursor-point col-6 py-sm center txt"} onClick={()=>setShowPending(true)}>Pending</div>
                    <div className={!showPending?"cursor-point col-6 py-sm center txt  active":"cursor-point col-6 py-sm center txt"} onClick={()=>setShowPending(false)}>Completed</div>
                </div>
                <div id="myPendingClasses" className="animated fadeInLeft" style={showPending?{"display":""}:{display:'none'}}>
                    {bookings.map(k =>{
                        console.log(Date.now());
                        if((new Date(k.start_time).getTime() > Date.now()) && k.isCancelled!==true)
                             return (<Booking booking={k} key={k.id} />)
                        }
                    )}
                </div>
                <div id="myCompletedClasses" className="animated fadeInLeft" style={!showPending?{"display":""}:{display:'none'}}>
                    {bookings.map(k =>{
                        console.log(k.isCancelled);
                            if((new Date(k.start_time).getTime() < Date.now()) || k.isCancelled===true){
                             return (<Booking booking={k} key={k.id} />)
                            }
                        }
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default connect(state => ({ bookings: state.userReducer.bookings }), null)(MyClass);
