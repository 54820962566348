import React from "react"

const BookingList = props => {
  //("props",props.from);
  const getBookingList = () => {
    return (
      <>
        <div className="date-month col-auto pl-0">
          <p>10:00</p>
          <p>11:00</p>
        </div>
        <div className="card-white border-radius class-list col-10">
          <span className="border-purple border-leftLine ml-sm" />
          <h6 className="mb-0 d-flex">
            Quran - Lesson 1 <i className="icon icon-More ml-auto" />
          </h6>
          <p>
            Zoom id: <span className="text-blue text-sbold">87345281730</span>
          </p>
        </div>
      </>
    )
  }
  const getHolidays = () => {
    return (
      <>
        <div className="date-card d-flex align-items-center">
          <div className="date-month">
            <div className="month">Jun</div>11
          </div>
          <div className="card mb-0">
            <h6 className="mb-0 d-flex">
              <strong>Wedding Anniversary</strong><i className="icon icon-More ms-auto"/>
            </h6>
          </div>
        </div>  
      </>
    )
  }
  return <div>
    {props.from == 'holidays' ? (getHolidays()) : (getBookingList())}
  </div>
}

export default BookingList
