import React, { useState } from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from '../components/dashboard/class'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { postMethod, putMethod } from '../helper/api';
import { navigate } from "gatsby";
import { notify } from 'react-notify-toast';

const Meet = ({ location, user }) => {

    // const [reason1, setReason1] = useState(null);
    // const [reason2, setReason2] = useState(null);
    // const [reason3, setReason3] = useState(null);
    // const [reason4, setReason4] = useState(null);
    // const [remarks, setRemarks] = useState(null);

    // async function postFeedback() {
    //     let cancelledBy = location.state.booking.booked_by.id;

    //     let reason = '';
    //     if (reason1)
    //         reason = reason1;
    //     if (reason2)
    //         reason += `,${reason2}`;
    //     if (reason3)
    //         reason += `,${reason3}`;
    //     if (reason4)
    //         reason += `,${reason4}`;


    //     let resp = await postMethod('/cancel-bookings', { booking: location.state.booking.id, reasons: reason, remarks: remarks, Cancelled_by: cancelledBy });
    //     let resp2 = await putMethod(`/bookings/${location.state.booking.id}`, "isCancelled=1");
    //     if (resp.error) {
    //         notify.show("Error: ", resp.error);
    //     } else {
    //         setTimeout(() => notify.show("Cancelled successfully", "success"), 100);
    //         navigate('/dashboard')
    //     }

    // }

    if (!location.state) {
        location.state = {}
    }

    let userRole = 'student';
    let roomUrl = "";
    let hostRoomUrl = "";

    if(user.authenticate.user){
        userRole = user.authenticate.user.user_role;
    }

    if(location.state.booking){
        roomUrl = location.state.booking.roomUrl;
        hostRoomUrl = location.state.booking.hostRoomUrl;
    }

    return (
        <Layout title="Meet">
            <Seo title="Meet" />
            <Booking booking={location.state.booking} />
            <div className="row m-sm d-block" style={{textAlign:"center"}}>
            
            {userRole==='student' && (<iframe
                src={roomUrl}
                allow="camera; microphone; fullscreen; speaker;"
                style={{height:"500px"}}
                ></iframe>)}
            {userRole==='faculty' && (<iframe
                src={`${hostRoomUrl}&recording=on`}
                allow="camera; microphone; fullscreen; speaker;"
                style={{height:"500px"}}
                ></iframe>)}
            </div>

            {/* <textarea></textarea> */}
        </Layout>
    );
}

export default connect(state => ({ user: state.loginReducer}), null)(Meet);
