import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from '../components/dashboard/class'
import { getMethod, postMethod, putMethod } from "../helper/api"


const MyDisputes = ({userDetails}) => {

    //const [showPending, setShowPending] = useState(true);
    const [disputes, setDisputes] = useState([]);

    console.log('my-d::',userDetails);

    useEffect(()=>{

        async function getBooking(id){
            let book = await getMethod('/bookings', '', {'id':id});
            return book.data[0];
        }

        async function getDisputes(){
            let sub2 = await getMethod('/disputes', '', {'tutor':userDetails.id,is_resolved_ne:true});
            
            for(let i=0; i<sub2.data.length;i++){
                sub2.data[i].booking = await getBooking(sub2.data[i].booking.id);
                sub2.data[i].booking.dispute = sub2.data[i];
            }
            setDisputes(sub2.data);
        }

        getDisputes();
    },[]);
    
    return (
        <Layout title="Disputes">
            <Seo title="Disputes" />
            <div className="my-classes">
                <div className="container">
                    <div id="myPendingClasses" className="animated fadeInLeft">
                        {disputes.map(k =>{

                            //if((new Date(k.start_time).getTime() > Date.now()) && k.isCancelled!==true)
                                return (<Booking booking={k.booking} userDetails={userDetails} key={k.id} />)
                            //}
                        })}
                    </div>
                </div>
            </div>    
        </Layout>
    );
}

export default connect(state => ({ userDetails: state.loginReducer.authenticate.user }), null)(MyDisputes);
