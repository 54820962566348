import { LOGIN_TYPES } from '../../types/loginTypes';

const initialState = {
  authenticate: {},
  signUpUser: false,
  updateProfile: false,
};

export default (state = initialState, action) => {

  switch (action.type) {
   
    case LOGIN_TYPES.GET_LOGIN_DETAILS_SUCCESS:
      return {
        ...state,
        authenticate: action.data,
        signUpUser: true
      };
      case LOGIN_TYPES.PROFILE_UPDATE_SUCCESS:
        return {
          ...state,
          updateProfile: true
        };
    default:
      return state;
  }
};
