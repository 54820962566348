import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from '../components/dashboard/class'
import { getMethod, postMethod, putMethod } from "../helper/api"


const MyClass = () => {

    const [showPending, setShowPending] = useState(true);
    const [bookings, setBookings] = useState([]);

    useEffect(()=>{

        async function getBookings(){
            let sub2 = await getMethod('/bookings', '', {'payment':1,"_sort":"start_time"});
            setBookings(sub2.data);
        }

        getBookings();
    },[]);
    
    return (
        <Layout title="My Lessons">
            <Seo title="Home" />
            <div className="my-classes">
                <div className="container">
                    <div className="nav-tab">
                        <div className={showPending?"tab-link active":"tab-link"} onClick={()=>setShowPending(true)}>Pending</div>
                        <div className={!showPending?"tab-link active":"tab-link"} onClick={()=>setShowPending(false)}>Completed</div>
                    </div>
                    <div id="myPendingClasses" className="animated fadeInLeft" style={showPending?{"display":""}:{display:'none'}}>
                        {bookings.map(k =>{

                            if((new Date(k.start_time).getTime() > Date.now()) && k.isCancelled!==true)
                                return (<Booking booking={k} key={k.id} />)
                            }
                        )}
                    </div>
                    <div id="myCompletedClasses" className="animated fadeInLeft" style={!showPending?{"display":""}:{display:'none'}}>
                        {bookings.map(k =>{
                            //console.log(k.isCancelled);
                                if((new Date(k.start_time).getTime() < Date.now()) || k.isCancelled===true){
                                return (<Booking booking={k} key={k.id} />)
                                }
                            }
                        )}
                    </div>
                </div>
            </div>    
        </Layout>
    );
}

export default connect(state => ({ bookings: state.userReducer.bookings }), null)(MyClass);
