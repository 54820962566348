import React, { useState } from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from '../components/dashboard/class'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { postMethod, putMethod } from '../helper/api';
import { navigate } from "gatsby";
import { notify } from 'react-notify-toast';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Cancel = ({ location, userDetails }) => {

    const [reason1, setReason1] = useState(null);
    const [reason2, setReason2] = useState(null);
    const [reason3, setReason3] = useState(null);
    const [reason4, setReason4] = useState(null);
    const [remarks, setRemarks] = useState(null);

    function confirm() {

        let buttons = [
            {
                label: 'No',
                //className: 'bg-red'
            },
            {
                label: 'Keep Lesson',
                onClick: () => postFeedback(false),
                className: 'bg-blue'
            },
            {
                label: 'Donate',
                onClick: () => postFeedback(true),
                className: 'bg-green'
            },
        ];

        if(userDetails.user_role==='faculty'){
            buttons = [
                {
                    label: 'Yes',
                    onClick: () => postFeedback(false),
                    className: 'bg-red'
                },
                {
                    label: 'No',
                    className: 'bg-green'
                }
            ];
        }
        
        let cancelTitle = "Are you sure you want to cancel this lesson ?"
        let cancelMsg = "";

        if(userDetails.user_role==='faculty'){
            
        }else if(((new Date(location.state.booking.start_time).getTime() - new Date().getTime())/(1000*60*60)) <= 6 && (new Date(location.state.booking.start_time).getTime() - new Date().getTime())/(1000*60*60)>3){
            let cancelValue = (new Date(location.state.booking.end_time).getTime() - new Date(location.state.booking.start_time).getTime())/(1000*60*60)
            cancelMsg = `50% Cancellation fee will be applicable and donated.`

            buttons = [
                {
                    label: 'No',
                    //className: 'bg-red'
                },
                {
                    label: 'Cancel and Donate(50%)',
                    onClick: () => postFeedback("partial"),
                    className: 'bg-green'
                },
            ];

            
        }else if((new Date(location.state.booking.start_time).getTime() - new Date().getTime())/(1000*60*60)<3){
            cancelMsg = `100% Cancellation fee will be applicable and donated.`
            buttons = [
                {
                    label: 'No',
                    //className: 'bg-red'
                },
                {
                    label: 'Cancel and Donate',
                    onClick: () => postFeedback(true),
                    className: 'bg-green'
                },
            ];
        }

        confirmAlert({
            title: cancelTitle,
            message: cancelMsg,
            buttons: buttons
        });
    }

    async function postFeedback(donate) {



        let cancelledBy = location.state.booking.booked_by.id;

        let reason = '';
        if (reason1)
            reason = reason1;
        if (reason2)
            reason += `,${reason2}`;
        if (reason3)
            reason += `,${reason3}`;
        if (reason4)
            reason += `,${reason4}`;


        let resp = await postMethod('/cancel-bookings', { booking: location.state.booking.id, reasons: reason, remarks: remarks, Cancelled_by: cancelledBy, donate: donate });
        let resp2 = await postMethod('/cancelbooking',{id:location.state.booking.id, donate: donate });
        if (resp.error) {
            notify.show("Error: ", resp.error);
        } else {
            setTimeout(() => notify.show("Cancelled successfully", "success"), 100);
            navigate('/dashboard')
        }

    }

    if (!location.state) {
        location.state = {}
    }

    return (
        <Layout title="Cancel">
            <Seo title="Cancel" />
            <Booking booking={location.state.booking} />
            <div className="cancel-section">
                <div className="container">
                    <h5>Reason for Cancelling</h5>
                    <form className="cancelClass mb-3">

                        {userDetails.user_role==='student' && <><div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Time not suitable" onClick={event => { setReason1(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Time not suitable</label >
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Having school exams" onClick={event => { setReason2(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Having school exams</label >
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Vacation" onClick={event => { setReason3(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Vacation</label >
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Not liking the teacher" onClick={event => { setReason4(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Not liking the teacher</label >
                        </div></>}

                        {userDetails.user_role==='faculty' && <><div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Internet Issues" onClick={event => { setReason1(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Internet Issues</label >
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Emergency Work" onClick={event => { setReason2(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Emergency Work</label >
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Unwell" onClick={event => { setReason3(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Unwell</label >
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Power Cut" onClick={event => { setReason4(event.target.checked ? event.target.value : null) }} />
                            <label className="form-check-label">Power Cut</label >
                        </div></>}
                    </form>
                    <label className="sm">Remarks</label>
                    <textarea className="form-control" rows="3" cols="45" name="remark" placeholder="Enter your remarks" onChange={event => setRemarks(event.target.value)}></textarea>
                    <div className="fixed-btn-wrapper">
                        <button type="button" className="btn btn-danger w-100" onClick={() => confirm()}>Cancel</button>
                    </div>
                </div>
            </div>    
        </Layout>
    );
}

export default connect(state => ({ bookings: state.userReducer.bookings, userDetails: state.loginReducer.authenticate.user }), null)(Cancel);
