
export const isBrowser = typeof window !== "undefined";



export const getWindowItem =  (itemName, defaultValue) => {
    if(isBrowser){
        return window.localStorage.getItem(itemName)
    }

    return defaultValue;
};

export const setWindowItem =  (itemName, itemValue) => {
    if(isBrowser){
        return window.localStorage.setItem(itemName, itemValue)
    }
};

export const showTime =  (dateTime) => {
    let dateArray = dateTime.split('T');
    let time = dateArray[1].slice(0,5);
    return time;
};