import React, {useEffect} from 'react';
import { useDispatch, connect } from 'react-redux';
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import firebase from "gatsby-plugin-firebase";
import {loginCheck} from '../state/actions/login/loginAction';
import { navigate } from "gatsby"  


const IndexPage = ({user}) => {
 
 let [mobileNo, setMobileNo] = React.useState("");
 const dispatch = useDispatch();

  useEffect(()=>{
    if (user.authenticate && user.authenticate.user && (!user.authenticate.user.firstname || !user.authenticate.user.lastname)) {
      navigate('/SignUp/');
    }else if(user.authenticate.user && user.authenticate.jwt){
      navigate('/dashboard/');
    }
  },[user.authenticate.user])

 function getGoogleSignin (){
  //console.log("calling auth:", mobileNo, appVerifier);
  const appVerifier = window.recaptchaVerifier;
  mobileNo = '+919731174566';
  firebase.auth().signInWithPhoneNumber(mobileNo, appVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      confirmationResult.confirm("123456").then((user)=>{
       let userAuth = {'token':user.user.Aa}
        dispatch(loginCheck(userAuth));
      });
    
    }).catch((error) => {
      
      console.log("failed");
      console.log("error",error);
    });
 }

 async function signIn(){
  alert("initialized");
  window.recaptchaVerifier =  new firebase.auth.RecaptchaVerifier('sign-in-button', {
   'size': 'invisible',
   'callback': (response) => {
     // reCAPTCHA solved, allow signInWithPhoneNumber.
     //console.log(response);
   }
  });
  getGoogleSignin();
 }

 return (
  
  <Layout>
    <Seo title="Home" />
    <div className="row body-logo">
		  <StaticImage src="../images/logo.png" alt="logo" className="img-center" />
	  </div>
    <div className="row">
		  <h1>Welcome Back</h1>
		  <p>Signin to continue</p>
	  </div>
	  <div className="row">
      <div className="btn ">
        <div className="input-icon">
          <StaticImage src="../images/flag.png" alt="flag" className="country-logo"/>
          <i className="icon icon-Down"></i>
          
        </div>
        <input type="text" id="loginID" tabIndex="1" name="mobile" className="login-input" placeholder="Enter Mobile Number to Login" maxLength="80" onChange={event=>setMobileNo(
          event.target.value
        )} />
        
       </div>
       <div className="row" id="sign-in-button"></div>
    
        <div className="row" >
       
        <div className="btn btn-lg btn-white my-lg" style={{cursor:'pointer',zIndex:1000}} onClick={()=>signIn()}>
			  Sign In
        </div>
         
         </div>
    </div> 
    <div className="px-lg">
      <hr className="hr-text" data-content="or" />
    </div>
	  <div className="row">
		  <Link to="../SignUp">
        <div className="btn btn-lg btn-white my-lg">
			  <span>Sign up</span>
        </div>
      </Link>
	  </div>
    {/* <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p> */}
  </Layout>
)



}




export default connect(state=>({user: state.loginReducer}),null)(IndexPage)
