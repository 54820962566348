import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SlotComponent from "../components/teacher/slots"
import { getMethod } from "../helper/api"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"

//import MoreVertIcon from '@material-ui/icons/MoreVert';

const ITEM_HEIGHT = 48

const Slot = ({ location }) => {
  let [slots, setSlots] = useState([])
  let [slotType, setSlotType] = useState("Once")
  const [value, onChange] = useState(new Date())

  if (!location.state) {
    location.state = { subject: {}, faculty: {} }
  }

  let faculty = location.state.faculty

  useEffect(() => {
    async function getSlots() {
      let res = await getMethod(
        "/slots",
        {},
        {
          faculty: faculty.id,
          isBooked_ne: true,
          date: new Date(value.valueOf() + 1000 * 60 * 60 * 5.5)
            .toISOString()
            .slice(0, 10),
        }
      )
      setSlots(res.data)
    }

    getSlots()
  }, [slots.length, value, slotType])

  return (
    <Layout title="Book a Lesson">
      <Seo title="Level" />
      <h5 style={{ paddingLeft: "30px" }}>Schedule</h5>
      <div
        className={
          slotType === "Once" ? "btn btn-sm btn-blue" : "btn btn-sm btn-white"
        }
        onClick={() => {
          setSlotType("Once")
          setSlots([])
        }}
      >
        Once
      </div>
      <div
        className={
          slotType === "Recurring"
            ? "btn btn-sm btn-blue"
            : "btn btn-sm btn-white"
        }
        onClick={() => {
          setSlotType("Recurring")
          setSlots([])
        }}
      >
        Recurring
      </div>
      <div style={{ width: "80%", margin: "auto", padding: "10px" }}>
        <Calendar
          onChange={onChange}
          value={value}
          minDate={new Date()}
          maxDate={new Date(Date.now() + 1000 * 60 * 60 * 24 * 30)}
        />
      </div>
      {slots.length > 0 && (
        <h5 style={{ paddingLeft: "30px" }}>Select a Slot</h5>
      )}
      {slots.length > 0 && (
        <div style={{ maxHeight: "150px", overflowY: "auto" }}>
          {slots.map(i => (
            <SlotComponent
              slot={i}
              key={i.id}
              level={location.state.level}
              subject={location.state.subject}
              faculty={location.state.faculty}
            />
          ))}
        </div>
      )}
    </Layout>
  )
}

export default Slot
