import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HolidayList from "../components/dashboard/teacher/booking-list"
import Select from "react-select"

const TeacherSchedule = () => {
  const [value, onChange] = useState(new Date());
  const [reason,setReason] = useState(null); 

  return (
    <Layout title="Holidays" children={`<a href="fsdfsdf>aafaafa</a>`}>
      <Seo title="Home" />
     
    <div className="container animated fadeInLeft mt-sm">
    <HolidayList from={"holidays"}/>
    </div>

    </Layout>
  )
}

export default TeacherSchedule
