import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import Select from "react-select"

const TeacherSchedule = () => {
  const [value, onChange] = useState(new Date());
  const [reason,setReason] = useState(null); 

  const addHolidays = () => {
    //console.log("data", reason,value)
  }
  return (
    <Layout title="Add holidays">
      <Seo title="Home" />
      <div className="container">
        <Calendar
          onChange={onChange}
          value={value}
          minDate={new Date()}
          maxDate={new Date(Date.now() + 1000 * 60 * 60 * 24 * 30)}
        />
        <label className="sm">Reason</label>
        <textarea
          className="form-control"
          rows={3}
          cols={45}
          name="remark"
          placeholder="Enter your Reason"
          defaultValue={""}
          onChange={event=>{setReason(event.target.value)}}
        />

        <div className="fixed-btn-wrapper">
          <div
            class="btn btn-primary w-100"
            onClick={() => addHolidays()}
          >
            <span>Save</span>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TeacherSchedule
