import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from '../components/dashboard/class'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { getMethod } from '../helper/api';
import { navigate } from "gatsby";
import { notify } from 'react-notify-toast';
import { CardActionArea } from '@material-ui/core';

const Wallet = ({ location }) => {

    
    const [deals, setDeals] = useState([]);
    const [selectedDeal, setSelectedDeal] = useState(null);

    if (!location.state) {
        location.state = {cart:{id:'', totalHrs:0}}
    }

    useEffect(() => {
        // getMyReview()
        async function getDeals() {
            let deals = await getMethod('/payment-options', '', {});
            setSelectedDeal(deals.data[0].id);
            setDeals(deals.data);
        }

        getDeals();
    }, [])

    async function payNow(){
        if (!location.state.cart) {
            location.state = {cart:{id:'', totalHrs:0}}
        }
        let resp = await getMethod('/payment', '', {id: selectedDeal, c: location.state.cart.id, t: location.state.cart.totalHrs});
        window.location.href=resp.data.payment_url;
    }

    

    return (
        <Layout title="Wallet">
            <Seo title="Wallet" />
            <div className="container">
             {deals?.map((deal, i) => {
                 if(deal.total_hrs < location.state.balancePaymentHrs) return(<></>);
                 return (<div key={i} className={selectedDeal===deal.id?`card wallet-payment bg-primary text-white`:`card wallet-payment with-border`} onClick={()=>{setSelectedDeal(deal.id)}}>
                        <div className="d-flex align-items-center">
                            <div className="price-block">
                                <div className="price">
                                    <strong>EGB {deal.price.toFixed(2)}</strong>
                                    <span className="del">EGB {(deal.actual_price).toFixed(2)}</span>
                                    <span className="forHrs reachAmount">
                                        - <strong>{deal.total_hrs}</strong> <span className="thin">hrs</span>
                                    </span>
                                </div>    
                                <div className="per-lession">
                                    EGB {(deal.price/deal.total_hrs).toFixed(2)}/Lesson
                                </div>
                            </div>
                            <div className="save">
                                <div className="savedAmount">Save EGB {((deal.actual_price)-(deal.price)).toFixed(2)}</div>
                            </div>
                        </div>
                    </div>)
            })}
            </div>
            <div className="fixed-btn-wrapper">
                <a onClick={()=>payNow()}><div className="btn btn-primary w-100">Proceed to Pay</div></a>
            </div>
        </Layout>
    );
}

export default connect(state => ({ bookings: state.userReducer.bookings }), null)(Wallet);
