import { combineReducers } from "redux";
import loginReducer from "./reducers/login/loginReducer";
import userReducer from "./reducers/user/userReducer";
import bookingReducer from "./reducers/booking/bookingReducer"

export default combineReducers({
    loginReducer,
    userReducer,
    bookingReducer
});
