import 'firebase/auth'

const config = {
    apiKey: "AIzaSyAdPuQIK-hnEz09vxYxGLYT5ypOMnLM3uM",
    authDomain: "wide-decoder-255614.firebaseapp.com",
    projectId: "wide-decoder-255614",
    storageBucket: "wide-decoder-255614.appspot.com",
    messagingSenderId: "771290168402",
    appId: "1:771290168402:web:4c5c7e7de0185ef292738b",
    measurementId: "G-HVFBSCELDQ"
 // if present
  }
  export default config