import React from 'react';
import { navigate } from 'gatsby';
import Layout from "../components/layout"
import Seo from "../components/seo"
//import MoreVertIcon from '@material-ui/icons/MoreVert';


const ITEM_HEIGHT = 48;

const Level = ({location}) => {
    
    const showTutors = (level) => {
        navigate(`/booking-teacher`, { state: { subject: location.state.subject, level: level } })
    }

    return (
        <Layout title="Book a Lesson">
        <Seo title="Level" />
        <h5 style={{ paddingLeft: '30px' }}>Select Course Level</h5>
        <div className="row m-sm" key={1}>
            <div className='card-white border-radius class-list col-10'>
                <span className="border-purple border-leftLine ml-sm" />
                <h6 className="mb-0 d-flex">Beginner</h6>
            </div>
            <i
                className='icon icon-Right ml-auto a-self-center'
                style={{ fontSize: 40, cursor: 'pointer' }}
                onClick={()=>showTutors("Beginner")}
            />
        </div>
        <div className="row m-sm" key={2}>
            <div className='card-white border-radius class-list col-10'>
                <span className="border-purple border-leftLine ml-sm" />
                <h6 className="mb-0 d-flex">Intermediate</h6>
            </div>
            <i
                className='icon icon-Right ml-auto a-self-center'
                style={{ fontSize: 40, cursor: 'pointer' }}
                onClick={()=>showTutors("Intermediate")}
            />
        </div>
        <div className="row m-sm" key={3}>
            <div className='card-white border-radius class-list col-10'>
                <span className="border-purple border-leftLine ml-sm" />
                <h6 className="mb-0 d-flex">Advanced</h6>
            </div>
            <i
                className='icon icon-Right ml-auto a-self-center'
                style={{ fontSize: 40, cursor: 'pointer' }}
                onClick={()=>showTutors("Advanced")}
            />
        </div>
        </Layout>
        );
}

export default Level;