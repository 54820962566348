import React, { useEffect } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {navigate} from 'gatsby';
import {connect} from 'react-redux';
import { getMethod, postMethod } from '../../helper/api';
import { setBookingDetails } from '../../state/actions/booking/bookingAction';
import {showTime} from '../../helper/globals'
//import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment'

  
const ITEM_HEIGHT = 48;

function Booking (props) {
    var booking = props.booking;
    var userDetails = props.userDetails;
    var showLessonHistory = props.showLessonHistory!==undefined?props.showLessonHistory:true
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [disputes,setDisputes] = React.useState(null);
    const [isReviewed,setIsReviewed] = React.useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const showReview = (booking) => {

        navigate(`/review`, { state: { booking: booking } })
        handleClose();
    }

    const showCancel = (booking) => {

        navigate(`/cancel`, { state: { booking: booking } })
        handleClose();
    }

    const showDispute = (booking) => {

        navigate(`/dispute`, { state: { booking: booking} })
        handleClose();
    }

    const viewDispute = (booking) => {

        navigate(`/viewdispute`, { state: { booking: booking} })
        handleClose();
    }
    
    const showReportCard = (booking) =>{

        navigate(`/report-card`, { state: { booking: booking } })
        handleClose();
    }

    const showMeet = async (booking) =>{

        //navigate(`/meet`, { state: { booking: booking } })
        let action = userDetails.user_role=='student'?'joined':'started';
        let booking_meet = await postMethod('/booking-meet-histories',{user:userDetails.id,booking:booking.id,action:action})
        window.open(`https://${booking.faculty.class_link}`,'_blank');
        handleClose();
    }

    useEffect(()=>{
        async function disputes() {
            if(booking===undefined) return;
            let disputes = await getMethod('/disputes', '', { booking:booking?.id });
            //console.log(disputes);
            if (disputes.status == 200 && disputes.data.length > 0) {
                setDisputes(disputes.data[0]);
            }
        }

        //if (userDetails?.user_role == 'faculty') {
            disputes();
        //}

        async function getIsReviewed() {

            let reviews = await getMethod('/reviews/', '', {booking:booking.id,postedBy:userDetails.id});

            if (reviews?.status == 200) {
                if(reviews.data.length>0){
                    setIsReviewed(true);
                }
            }
        }

        getIsReviewed();

    },[])

     //Object.assign(booking,{dispute:disputes});
     if(disputes)
        booking.dispute = disputes;
    //console.log("InBooking::",props.userDetails);

    // if(!props.booking){
    //     booking = {subject:{},faculty:{},slot:{}, start_time:new Date(), end_time: new Date()};
    // }

    //console.log("TTTT:::",new Date(booking.start_time.replace('Z','').replace('T',' ')));
    //console.log(booking.start_time);
    
    //console.log(booking);
    return (<>
        { booking && (<div className="date-card d-flex align-items-center" key={booking.id}>
            {/* {booking.start_time  && (<div className="date-month col-auto pl-0"><p>{new Date(booking.start_time).toLocaleString('default', { month: 'short' })}</p>{new Date(booking.start_time).getDate()}</div>)} */}
            {booking.date && booking.slotType!=='Recurring' && (<div className="date-month"><div className="month">{new Date(booking.date).toLocaleString('default', { month: 'short' })}</div>{new Date(booking.date).getDate()}<br/><div className="month">{moment(new Date(booking.date)).format('ddd')}</div></div>)}
            {booking.startDate && booking.slotType==='Recurring' && (<div className="date-month col-auto pl-0"><p>{new Date(booking.startDate).toLocaleString('default', { month: 'short' })}</p>{new Date(booking.startDate).getDate()} - <p>{new Date(booking.endDate).toLocaleString('default', { month: 'short' })}</p>{new Date(booking.endDate).getDate()}</div>)}
            <div className={!booking.isCancelled?'card mb-0':'card card-danger mb-0'}>
                <div className="class-desc">
                    <h6 className="d-flex"><strong style={{width:"90%"}}>{booking?.subject?.name}</strong>
                        {booking.isCancelled && booking.cancelled_by.id === userDetails.id && <span className="badge bg-light" style={{color:"red",fontWeight:"bold"}}>Cancelled by You</span>}
                        {booking.isCancelled && booking.cancelled_by.id !== userDetails.id && <span className="badge bg-light" style={{color:"red",fontWeight:"bold"}}>Cancelled by {userDetails.user_role==='student'?'Faculty':'Student'}</span>}
                        {(booking.dispute && Object.keys(booking.dispute).length > 0) && <span className="badge bg-light" style={{color:"red",fontWeight:"bold"}}>Disputed</span>}
                        {new Date(booking.endDate) < new Date() && !booking.isCancelled && !(booking.dispute && Object.keys(booking.dispute).length > 0) && <span className="badge bg-light" style={{color:"green",fontWeight:"bold"}}>Completed</span>}
                        {booking.id!==null && (<><i className="icon icon-More ms-auto" onClick={handleClick} />
                        
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            
                                { !booking.isCancelled && userDetails?.user_role=='faculty' && new Date(booking.end_time).getTime()>new Date().getTime() && <MenuItem key={1} onClick={()=>showMeet(booking)} >
                                    {/* <a href="https://www.whereby.com/ddmk?minimal" target="_blank">Meet</a> */}
				    <a href={`https://${booking.faculty.class_link.replace('https://','')}`} target="_blank" style={{textDecoration:'none', color:'unset'}}>Start Lesson</a>
                                </MenuItem>}

                                {!booking.isCancelled && userDetails?.user_role=='student' && new Date(booking.end_time).getTime()>new Date().getTime() && <MenuItem key={1} >
                                    {/* <a href="https://www.whereby.com/ddmk?minimal" target="_blank">Meet</a> */}
                                    <a href={`https://${booking.faculty.class_link.replace('https://','')}`} target="_blank" style={{textDecoration:'none', color:'unset'}}>Join Lesson</a> 
                                </MenuItem>}

                                { new Date(booking.end_time).getTime()<new Date().getTime() && !isReviewed && <MenuItem key={2} onClick={()=>showReview(booking)}>
                                    Review
                                </MenuItem>}
                                { showLessonHistory && new Date(booking.end_time).getTime()<new Date().getTime() && <MenuItem key={3} onClick={()=>showReportCard(booking)}>
                                    Lesson History
                                </MenuItem>}
                                { userDetails?.user_role=='student' && new Date(booking.end_time).getTime()>(new Date().getTime()- 3*1000*60*60) && <MenuItem key={4} onClick={()=>showCancel(booking)}>
                                    Cancel
                                </MenuItem>}

                                { userDetails?.user_role=='faculty' && new Date(booking.end_time).getTime()>(new Date().getTime()) && <MenuItem key={4} onClick={()=>showCancel(booking)}>
                                    Cancel
                                </MenuItem>}
                                {
                                    (booking.dispute && Object.keys(booking.dispute).length > 0) ? (<MenuItem key={5} onClick={()=>viewDispute(booking)}>
                                    View Dispute
                                </MenuItem>) :  userDetails?.user_role=='student' && (new Date().getTime()>new Date(booking.end_time)) &&  (new Date().getTime()-new Date(booking.end_time).getTime())<(1000*60*60*72) && (<MenuItem key={5} onClick={()=>showDispute(booking)}>
                                Raise Dispute
                                </MenuItem>)
                                }
                                
                        
                        </Menu></>)}

                    </h6>
                    {booking.start_time && 
                    (<div><span className="timing">{new Date(booking.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }-{new Date(booking.end_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }</span>
                    <span className="badge bg-light text-dark">{booking?.booked_by?.firstname}</span>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <span className="badge bg-light text-dark">{booking?.faculty?.firstname}</span>
                    </div>)}
                    {/* (<p>{new Date(booking.start_time).toISOString().slice(10, 16).replace('T', ' ') + ' - ' + new Date(booking.end_time).toISOString().slice(10, 16).replace('T', ' ')}<span className="teach-name-badge badge-purple">{booking.faculty.firstname}</span></p>)} */}
                    

                    {booking.time &&
                    (<div>{new Date(booking.time).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}<span className="badge bg-light">{booking?.faculty?.firstname}</span></div>)}

                    {booking.time &&
                    (<div>{new Date(booking.time).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}<span className="badge bg-light">{booking?.booked_by?.firstname}</span></div>)}
                </div>
            </div>
            
        </div>)}</>);
}

export default connect(
    state => ({ userDetails: state.loginReducer.authenticate.user }),
    null
)(Booking);
