import { BOOKING_TYPES } from '../../types/BookingType'

const initialState = {
  subject: {},
  bookingObject: {
    subject:"",
    teacher:"",
    dateType:"",
    date: "",
    slot:""
  }
}


export default (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_TYPES.SET_SUBJECT:
      return {
        ...state,
        subject: action.payload
      }
    case BOOKING_TYPES.BOOKING_DETAILS:
    
     if(action.payload.subject){
      state.bookingObject.subject = action.payload.subject;
     }
     if(action.payload.teacher){
      state.bookingObject.teacher = action.payload.teacher;
     }
     if(action.payload.dateType){
      state.bookingObject.dateType = action.payload.dateType;
     }
     if(action.payload.date){
      state.bookingObject.date = action.payload.date
     }
     if(action.payload.slot){
      state.bookingObject.slot = action.payload.slot
     }
     //console.log("sfasffsfasfas",state.bookingObject)
      return {
        ...state,
        bookingObject: state.bookingObject
      }
    default:
      return state
  }
}
