import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Today from "../components/dashboard/today"
import Wallet from "../components/dashboard/wallet"
import ReportCard from "../components/dashboard/report-card"
import { getMethod, postMethod, putMethod } from "../helper/api"
import { updateBookings } from "../state/actions/user/useraction"
import { setSubject } from "../state/actions/booking/bookingAction"
import BookALesson from "../components/dashboard/book-a-lesson-component"
import Earnings from "../components/dashboard/teacher/earnings"
import Analytics from "../components/dashboard/teacher/analytics"
import BookingList from "../components/dashboard/teacher/booking-list"
import { navigate, Link } from "gatsby"
import { isBrowser } from '../helper/globals';
import { Cookies } from "react-cookie"
import firebase from 'gatsby-plugin-firebase';


const Dashboard = ({ userDetails, location, data }) => {
    var [bookings, setBookings] = useState([])
    var [subjects, setSubjects] = useState({})
    var [wallet, setWallet] = useState({})
    const [totalHrs, setTotalHrs] = useState(0);
    const [earnings, setEarnings] = useState(0);
    const [payments, setPayments] = useState(0);
    const [rating, setRating] = useState(0);
    const [booking, setBooking] = useState("");
    const [disputes, setDisputes] = useState([]);

    let firstname, rest, user_role;

    const dispatch = useDispatch();

    function updateBookingData(data) {
        dispatch(updateBookings(data));
    }

    useEffect(()=>{
        let cookie = new Cookies()
        if(!cookie.get('token')){
            navigate('/');
        }

        
    },[])
    useEffect(() => {
        if (isBrowser) {
            const params = new URLSearchParams(location.search);
            const booking = params.get("booking");
            if (booking === 'success') {
                window.localStorage.removeItem('cart');
                window.localStorage.removeItem('date');
                window.localStorage.removeItem('duration');
                window.localStorage.removeItem('selectedTeacher');
                window.localStorage.removeItem('days');
                window.localStorage.removeItem('enddate');
                window.localStorage.removeItem('slots');
                window.localStorage.removeItem('selectedSubject');
                window.localStorage.removeItem('level');
                window.localStorage.removeItem('startdate');
                window.localStorage.removeItem('slotType');
                window.localStorage.removeItem('selectedSubjectValues');
                window.localStorage.removeItem('state');
            }
        }
    }, []);

    

    useEffect(() => {
        getMyDetails()
        getSubjects()
        getWallet()
        reportCard()
        
        if(userDetails?.user_role==='student') getMetrics();
        if(userDetails?.user_role==='faculty') {
            getUnReviewed();
            getTutorPayments();
        }

        async function getMyDetails() {
            let sub2 = await getMethod('/bookings', '', { 'payment': 1, 'isCancelled_ne': true, 'date_gte': new Date().toISOString().slice(0, 19).replace("T", " "), "_sort":'start_time' });
            setBookings(sub2.data);
            updateBookingData(sub2.data);
        }

        async function getSubjects() {
            let sub = await getMethod('/subjects', '', {});
            setSubjects(sub.data);
            dispatch(setSubject(sub.data));
        }

        async function getWallet() {
            //console.log(userDetails);
            if (userDetails?.id) {
                let wallet = await getMethod('/wallets', '', { user_ids_in: [userDetails?.id] });
                if (wallet.status == 200) setWallet(wallet.data[0]);

            }

        }

        async function reportCard() {

            let sub2 = await getMethod('/reviews', '', { postedFor: userDetails?.id, _limit: 1 });

            if (sub2.status == 200 && sub2.data.length > 0) {
                //setRating(sub2.data[0].rating);
                setBooking(sub2.data[0].booking);
            }
        }

        async function getMetrics() {

            let sub2 = await getMethod('/user/metrics', '', {});

            if (sub2.status == 200) {
                if(sub2.data.rating.length>0)
                    setRating(sub2.data.rating[0]?.avgrating);
                if(sub2.data.hrs.length>0)
                    setTotalHrs(sub2.data.hrs[0]?.hrs);
            }
        }

        async function getTutorPayments() {

            let sub2 = await getMethod('/tutor/payments', '', {});

            if (sub2.status == 200) {
                if(sub2.data.total_payments.length>0)
                    setEarnings(sub2.data.total_payments[0]?.total_earnings);
                if(sub2.data.payments.length>0)
                    setPayments(sub2.data.payments);
            }
        }

        async function getUnReviewed() {

            let bookings = await getMethod('/tutor/unreviewed', '', {});

            if (bookings?.status == 200) {
                if(bookings.data.length>0){
                    navigate('/review',{state:{booking:bookings.data[0]}})
                }
            }
        }

        

        async function disputes() {

            let disputes = await getMethod('/disputes', '', { tutor: userDetails?.id, is_resolved_ne: true });

            if (disputes.status == 200 && disputes.data.length > 0) {
                setDisputes(disputes.data);
            }
        }

        if (userDetails?.user_role == 'faculty') {
            disputes();
        }

    }, [firstname, userDetails])



    if (userDetails) {
        ; ({ firstname, user_role, ...rest } = userDetails)
        if (firstname === null || firstname === "") {
            //navigate('/profile');
        }
    }

    return (
        <div className="page-wrapper animated fadeInLeft">
            <Layout title="Dashboard">
                <Seo title="Home" />
                <div className="welcome-section section">
                    <div className="container">
                        <div className="card card-info">
                            <h4 className="mt-0">Welcome {firstname}</h4>
                            {userDetails?.user_role == 'student' && totalHrs>0 && (
                                <p className="m-0">
                                    You’ve successfully completed{" "}
                                    <strong>{totalHrs}</strong> hrs till date! Keep
                                    it up and score for high grade.
                                </p>)}
                        </div>
                    </div>
                </div>
                <div className="stats-section section">
                    <div className="container">
                        <div className="row gx-3">
                            {user_role === "student" ? (
                                <>
                                    <div className="col-6">
                                        <BookALesson subjects={subjects} />
                                    </div>
                                    <div className="col-6">
                                        <div className="card stats">
                                            <Link to="/my-classes" className="link-overlay"></Link>
                                            <div className="stats-title d-flex">
                                                My Lessons
                                                <i className="icon icon-Right-Arrow text-primary ms-auto" />
                                            </div>
                                            <div className="stats-data d-flex">
                                                <div>
                                                    <h3 className="text-primary mb-0">{bookings?.length}</h3>
                                                    <div className="stats-status">Pending</div>
                                                </div>
                                                <div className="ms-auto">
                                                    <i className="text-primary icon icon-Lesson"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <Wallet data={wallet} />
                                    </div>
                                    <div className="col-6">
                                        <ReportCard   rating={rating} subject={subjects[booking?.subject]} />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-6">
                                        <Earnings earnings={earnings} payments={payments}/>
                                    </div>
                                    <div className="col-6">
                                        <Analytics payments={payments}/>
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                </div>

                {disputes.length > 0 && (
                    <div className="today-class section">
                        <div className="container">
                            <div className="section-title d-flex align-items-center">
                                <div className="title">
                                    <h5 className="mb-0">You have active disputes </h5>

                                </div>
                                <span className="ms-auto">
                                    <Link to="/my-disputes" className="btn btn-primary btn-sm">View</Link>
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                <div className="today-class section">
                    <div className="container">
                        {user_role === "student" ? (
                            <><Today bookings={bookings} subjects={subjects} role="student" />{" "}</>
                        ) : (
                            <><Today bookings={bookings} subjects={subjects} role="faculty" />{" "}</>
                        )}
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default connect(
    state => ({ userDetails: state.loginReducer.authenticate.user }),
    null
)(Dashboard)
