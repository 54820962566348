import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from '../components/dashboard/class'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { getMethod } from '../helper/api';
import { navigate } from "gatsby";
import { notify } from 'react-notify-toast';
import { CardActionArea } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const Wallet = ({ location, userDetails }) => {


    const [transactions, setTransactions] = useState([]);
    const [wallet, setWallet] = useState({});


    useEffect(() => {
        // getMyReview()

        async function getWallet() {
            let walletData = await getMethod('/wallets', '', { user_ids_in: [userDetails.id] });
            setWallet(walletData.data[0]);
        }

        async function getTransactions() {
            let transactions = await getMethod('/wallet-transactions?_sort=id:DESC', '', { user_id: userDetails.id });

            for(let i=0; i<transactions.data.length;i++){
                //console.log(transactions["data"][i]);
                    if(transactions["data"][i].description.indexOf('cartId:')!==-1){
                        let cartDetails = await getMethod('/carts',"",{id:transactions["data"][i].description.replace('cartId:','').trim()});
                        Object.assign(transactions["data"][i],{'subject':cartDetails["data"][0].subject})
                        Object.assign(transactions["data"][i],{'teacher':cartDetails["data"][0].faculty})
                    }
            }

            setTransactions(transactions.data);
        }

        getTransactions();
        getWallet();
    }, []);

    async function topUp(){
        navigate('/wallet-payment');
    }

    async function showReportCard(item){
        let bookingId = item.description.substr(item.description.indexOf(':')+1);
        bookingId = bookingId.substr(0,bookingId.indexOf(','));
        let booking = await getMethod(`/bookings/${bookingId.trim()}`);
        navigate(`/report-card`, { state: { booking: booking.data } })
    }

    if (!location.state) {
        location.state = {}
    }

    return (
        <Layout title="Wallet">
            <Seo title="Wallet" />
            {location.state.booking!==undefined && <Booking booking={location.state.booking} />}
            <div className="wallet-transition">
                <div className="container">
                    <div className="wallet-card card bg-primary text-white">
                        <div className="row align-items-center gx-3">
                            <div className="col ps-4">
                                <div>Balance</div>
                                <h3 class="hours text-white mb-0">{wallet.balance_hrs}<sub>hrs</sub></h3>
                            </div>
                            <div className="col-auto">
                                <a onClick={topUp} class="btn btn-sm btn-light mb-0">Topup</a>
                            </div>
                        </div>
                    </div>
                    <h5 className="pt-2">Transaction</h5>
                    <div className="table-responsive transition-table">
                        <table className="table">
                            <thead className="table-light">
                                <tr className="table-heading">
                                    <th>Date</th>
                                    <th>Paid</th>
                                    <th>No. of Hrs</th>
                                    <th>Balance Hrs</th>
                                    <th>Details</th>
                                </tr>
                            </thead>

                            {transactions.map((item) => {
                                console.log(item);
                                return (
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="text-center">{new Date(item.date).toDateString().slice(4, 10)}{new Date(item.date).toISOString().slice(10, 16).replace('T', ' ')}</div>
                                            </td>
                                            <td className="hrs text-black">{item.amount ? `EGB ` : ' '}{item.amount ? item.amount.toFixed(2) : '  '}</td>
                                            <td className={item.type=='debit'?'text-center text-danger':'text-center text-success'}>{item.type=='debit'?'-':'+'}{item.booked_hrs}</td>
                                            <td className="hrs text-center text-black">{item.balance_hrs}</td>
                                            {item?.description?.indexOf('Cancelled')===-1 && 
                                            <td className="hrs text-black" onClick={()=>{ navigate(`/report-card`, { state: { booking: item } })}} >
                                            
                                                {!item?.subject?item.description:`${item?.subject?.name || ''}  ${item?.teacher?.firstname || ''} ${new Date(item?.date).toLocaleDateString('en-GB') || ''} `}
                                            
                                            </td>}
                                            {item?.description?.indexOf('Cancelled')>=0 && item?.description?.indexOf('Donate: true')>0 &&
                                            <td className="hrs text-black" onClick={()=>{ showReportCard(item) }} >
                                            
                                                Cancelled & Donated <br/> <a style={{fontSize:10, color:"green", cursor:"pointer"}} >Show Booking</a>
                                            
                                            </td>}

                                            {item?.description?.indexOf('Cancelled')>=0 && item?.description?.indexOf('Donate: true')===-1 &&
                                            <td className="hrs text-black" onClick={()=>{ showReportCard(item) }} >
                                            
                                                Cancelled
                                                <br/> <a style={{fontSize:10, color:"green", cursor:"pointer"}}>Show Booking</a>
                                            
                                            </td>}
                                        </tr>
                                    </tbody>
                                )
                            })}
                        </table>
                    </div>    
                </div>
            </div>        
        </Layout>
    );
}

export default connect(state => ({ userDetails: state.loginReducer.authenticate.user }), null)(Wallet);
