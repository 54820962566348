import { USER_TYPES } from '../../types/userTypes';

const initialState = {
  bookings: []
};

export default (state = initialState, action) => {

  switch (action.type) {
   
    case USER_TYPES.UPDATE_CLASSES:
      return {
        ...state,
        bookings: action.data,
      };
    default:
      return state;
  }
};
