import React, { useState,  useEffect, useCallback } from "react"
import { useDispatch, useSelector, connect, useReducer } from "react-redux"
import Paper from "@material-ui/core/Paper"
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import {
  ViewState,
  EditingState,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler"
import {
  Scheduler,
  DayView,
  WeekView,
  Appointments,
  AppointmentTooltip,
  ConfirmationDialog,
  MonthView,
  AppointmentForm,
  Toolbar,
  DateNavigator,
  TodayButton,
  ViewSwitcher,
  EditRecurrenceMenu,
  Resources,
} from "@devexpress/dx-react-scheduler-material-ui"
import { fade } from "@material-ui/core/styles/colorManipulator"
import { makeStyles, alpha } from "@material-ui/core/styles"
import { getMethod, postMethod, putMethod } from "../../helper/api"
import { notify } from "react-notify-toast"
import { withStyles } from '@material-ui/core/styles';
import { navigate } from "gatsby";
import Rolldate from 'rolldate'


const useStyles = makeStyles(theme => ({
  todayCell: {
    backgroundColor: fade(theme.palette.primary.main, 0.1),
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.14),
    },
    "&:focus": {
      backgroundColor: fade(theme.palette.primary.main, 0.16),
    },
  },
  weekendCell: {
    backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    "&:hover": {
      backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    },
    "&:focus": {
      backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    },
  },
  today: {
    backgroundColor: fade(theme.palette.primary.main, 0.16),
  },
  weekend: {
    backgroundColor: fade(theme.palette.action.disabledBackground, 0.06),
  },
}))

const TimeTableCell = props => {
  const classes = useStyles()
  const { startDate } = props
  const date = new Date(startDate)

  if (date.getDate() === new Date().getDate()) {
    return <WeekView.TimeTableCell {...props} className={classes.todayCell} />
  }
  if (date.getDay() === 0 || date.getDay() === 6) {
    return <WeekView.TimeTableCell {...props} className={classes.weekendCell} />
  }
  return <WeekView.TimeTableCell {...props} />
}

const DayScaleCell = props => {
  const classes = useStyles()
  const { startDate, today } = props




  if (today) {
    return <WeekView.DayScaleCell {...props} className={classes.today} />
  }
  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
    return <WeekView.DayScaleCell {...props} className={classes.weekend} />
  }
  return <WeekView.DayScaleCell {...props} />
}

const BasicLayout = ({ onFieldChange, appointmentData, user, ...restProps }) => {

  const str = 'HH:mm';

  const onCustomFieldChange = nextValue => {
    onFieldChange({ type: nextValue })

    if(nextValue===3){
      //appointmentData.startDate = moment("2021-10-10 00:00:00");
      //appointmentData.endDate = moment("2021-10-10 23:59:00");

      //console.log(appointmentData);
      setLoad(load+1);
    }
  }

  function onStartDateChange(nextValue) {
    onFieldChange({ startDate: nextValue })
  }

  function onEndDateChange(nextValue) {
    onFieldChange({ endDate: nextValue })
  }

  function onStartTimeChange(nextValue) {
    onFieldChange({ startDate: nextValue })
  }

  function onEndTimeChange(nextValue) {
    onFieldChange({ endDate: nextValue })
  }

  function onTillDateChange(nextValue) {
    //
      onFieldChange({ tillDate: nextValue.target.value })
      if(new Date(nextValue.target.value).getTime()<new Date().getTime()){
      setDateError(true);
      }else{
        setDateError(false);
      }
  }

  function onisRepeatChange(nextValue) {
    onFieldChange({ isRepeat: nextValue })
  }

  function onIsRepeatDaysChange(nextValue) {
    onFieldChange({ rdays: nextValue })
  }

  let [sdays,setSDays] = useState({});
  let [load,setLoad] = useState(1);
  let [dateError,setDateError] = useState(false);
  let [holidayError,setHolidayError] = useState(false);
  const [days,setDays]  = useState({ "Monday": true, "Tuesday": true, "Wednesday": true, "Thursday": true, "Friday": false, "Saturday": false, "Sunday": true });

  const changeRdays = (config) => {
    days[config['day']] = config['val'];
    onIsRepeatDaysChange(days);
  }

  appointmentData.rdays = days;

  useEffect(()=>{
    function enableDisableButtons(){
      
      //if(appointmentData.type==='4'){
        //console.log("run::",formVisible);
        
        const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
       
        for(let i=2;i<commandButtons?.length; i++){

          //console.log(commandButtons[i]);
          //commandButtons[i].style.display = (appointmentData.type==='4' || appointmentData.type==='3')?'none':'';
        
          //if(appointmentData.type===1 && i==2){
            commandButtons[i].style.display = 'none';
          //}
        
        }

        if(dateError || appointmentData.type===undefined){
          commandButtons[1].style.display = 'none';
        }else{
          commandButtons[1].style.display = '';
        }

        if(appointmentData.type==='4'){
          commandButtons[1].style.display = 'none';
        }
    }
    
    enableDisableButtons();

    if(!appointmentData.tillDate){
      
      if(appointmentData.type===undefined){
        onCustomFieldChange(1)
        onisRepeatChange(true)
        onIsRepeatDaysChange({ "Monday": true, "Tuesday": true, "Wednesday": true, "Thursday": true, "Friday": false, "Saturday": false, "Sunday": true });
        onTillDateChange({"target":{"value":moment().add(7,"days").format('YYYY-MM-DD')}});
      }

    };

    async function checkHolidayBookings(){

      let user = await getMethod('/users/me');
      
      let checkbookings = await getMethod('/bookings',"",{ faculty:user.id, start_time_gte:moment(appointmentData.startDate).format('YYYY-MM-DDTHH:mm:00Z'), start_time_lt:moment(appointmentData.startDate).format('YYYY-MM-DDT23:59:59')});
      
      const commandButtons = document.getElementsByClassName('MuiDrawer-paper')[0]?.getElementsByTagName('button');
      if(checkbookings.data.length>0){

        commandButtons[1].style.display = 'none';
        setHolidayError(true);
      }else{
        commandButtons[1].style.display = '';
      }
    
    }

    console.log(appointmentData.type);
    if(appointmentData.type===5){
      checkHolidayBookings();
    }

    new Rolldate({
      el: '#untillDate',
      format: 'DD-MM-YYYY',
      beginYear: new Date().getFullYear(),
      value: moment().add(7,'days').format('DD-MM-yyyy'),
      lang: { 
          title: 'Select A Date', 
          cancel: 'Cancel', 
          confirm: 'Confirm', 
          year: '', 
          month: '', 
          day: '', 
          hour: '', 
          min: '', 
          sec: '' 
        },
      confirm: function(date) {
          //console.log(date)
          //user.authenticate.user.date_of_birth = date;
          //setIsUpdated(true);
          onTillDateChange({"target":{"value":moment(date,'DD-MM-yyyy').format('yyyy-MM-DD')}});
        },
    })
  },[load,dateError, appointmentData.type, appointmentData.isRepeat])

  useEffect(()=>{
    console.log(load);
    document.getElementsByClassName('main-sch')[0].scrollIntoView();
    setTimeout(()=>{document.body.style.overflow = 'hidden'},500);

    return () => {
      document.body.style.overflow = ''
    }
  },[])

  //console.log(moment().add(5,"days").format('YYYY-MM-DD'));

  //onStartDateChange(moment(appointmentData.startDate,'DD/MM/yyyy').format('yyyy-MM-DD'));
  

  return (
  <>
      {load && <div id="appointmentFormContainer" className="container">
        
      <AppointmentForm.Label text={appointmentData.type==='4' || appointmentData.type==='3'? appointmentData.title: 'type'} type="title" />
      {appointmentData.type!=='4' && <><AppointmentForm.Select
          value={appointmentData.type!==undefined?appointmentData.type:1}
          onValueChange={onCustomFieldChange}
          placeholder="Custom field"
          availableOptions={[
            { id: 1, text: "Available " },
            { id: 2, text: "Break" },
            { id: 5, text: "Holiday" },
            
          ]}
          style={{ width: '242px', textTransform:"uppercase" }}
          
        />
        {holidayError && <div style={{fontSize:'12px', color:'#FF0000'}}>You have active bookings for the day. Cancel them to set Holiday</div>}
        <span style={{fontSize:'10px'}}>AVAILABLE - Please note students will only be able to book a lesson at your available time
          <br/>BREAK - Please mark your break timings(ie, Lunch / Prayer)
        </span></>}
        
        <AppointmentForm.Label text="Shift Start Date" type="title" />
        <AppointmentForm.DateEditor
          placeholder="Date"
          value={appointmentData.startDate}
          onValueChange={onStartDateChange}
          excludeTime={true}
          readOnly={true}
        />

        {appointmentData.type!==5 && (<><AppointmentForm.Label text="Shift Start Time" type="title" />
        {/* <input
        type="time"
        placeholder="HH:MM am/pm"
        value={`${new Date(appointmentData.startDate).getHours()}:${new Date(appointmentData.startDate).getMinutes()===0?"00":new Date(appointmentData.startDate).getMinutes()}`}
        onChange={onStartTimeChange}
        min="09:00"
        max="20:30"
        // showSecond={false}
        // style={{ width: 300 }}
        // defaultValue={moment()}
        // onChange={onChange}
      /> */}

        <TimePicker

          showSecond={false}
          style={{ width: 100 }}
          defaultValue={moment(appointmentData.startDate)}
          onChange={onStartTimeChange}
          use12Hours={true}
          minuteStep={30}
          className="input-group"
          
        />
        <AppointmentForm.Label text="Shift End time " type="title" />
        <TimePicker
          showSecond={false}
          style={{ width: 100 }}
          defaultValue={moment(appointmentData.endDate)}
          onChange={onEndTimeChange}
          use12Hours={true}
          minuteStep={30}
          className="input-group"
          
        /></>)}
        <br />

        {appointmentData.type==='4' && 
        <div className="btn btn-danger w-100" onClick={()=>{navigate('/cancel',{state:{booking:appointmentData.bookingdata}});}}>Cancel</div>
        }

        {appointmentData.type!=='4' && 
        <AppointmentForm.BooleanEditor
          label="Repeat"
          type="title"
          value={appointmentData.isRepeat}
          onValueChange={onisRepeatChange}
          
        />}

        {appointmentData.isRepeat  && (
          <>
            <AppointmentForm.Label text="Repeat on days" type="title" />
            <div>
              <div className={days["Monday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => changeRdays({ "day": "Monday", "val": !days["Monday"] })}>M</div>
              <div className={days["Tuesday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => changeRdays({ "day": "Tuesday", "val": !days["Tuesday"] })}>T</div>
              <div className={days["Wednesday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => changeRdays({ "day": "Wednesday", "val": !days["Wednesday"] })}>W</div>
              <div className={days["Thursday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => changeRdays({ "day": "Thursday", "val": !days["Thursday"] })}>T</div>
              <div className={days["Friday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => changeRdays({ "day": "Friday", "val": !days["Friday"] })}>F</div>
              <div className={days["Saturday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => changeRdays({ "day": "Saturday", "val": !days["Saturday"] })}>S</div>
              <div className={days["Sunday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => changeRdays({ "day": "Sunday", "val": !days["Sunday"] })}>S</div>
            </div>
            <input type="hidden" value={appointmentData.rdays} />

            <AppointmentForm.Label text="Schedule expires on" type="title" />
            <label>
            {dateError && (<span className="text-red">Please enter a future date</span>)}
            <input
              id="untillDate"
              type="text"
              placeholder="End Date"
              //value={appointmentData.tillDate}
              onChange={onTillDateChange}
              className="form-control"
              min={moment().add(1,"days").format('YYYY-MM-DD')}
              readOnly
            />
            </label>
          </>
        )}
      </div>}
    </>
  )
}

const styles = (theme) => ({

  appointment: {
    borderRadius: '10px',
    '&:hover': {
      opacity: 0.6,
    },
    backgroundColor: "#01C1FF",
  }

});


const Appointment = ({ children, style, data, ...restProps }) => (
  <Appointments.Appointment
    {...restProps}
    style={
      data.type == 1
        ? {
          ...style,
          backgroundColor: "#01C1FF",
          borderRadius: "5px",
        }
        : data.type == 2
          ? {
            ...style,
            backgroundColor: "#FA011D",
            borderRadius: "5px",
          }
          : {
            ...style,
            backgroundColor: "#CAA110",
            borderRadius: "5px"
          }
    }

  >
    {children}
  </Appointments.Appointment>
);



const BookingSchedule = props => {
  const [currentDate, setDate] = useState(Date.now())
  const [currentViewName, serViewName] = useState("Day")
  const [isRepeat, setRepeat] = useState(false)
  const dispatch = useDispatch();
  const [data, setAppointmentData] = useState(props.data);

  const setCurrentViewName = nextViewName => {
    serViewName(nextViewName)
  }

  const setCurrentDate = nextDate => {
    setDate(nextDate)
  }

 

  async function submitSchedule(data) {
    try {
      let finaldata = [];
      for(let i=0;i<data.length;i++){
        if(data[i].type===5){
          data[i].startDate=moment(moment(data[i].startDate).format('YYYY-MM-DD 00:00:00'));
          data[i].endDate=moment(moment(data[i].endDate).format('YYYY-MM-DD 23:59:00'));
        }

        //console.log(data[i]);
        //Add Slots only for Available
        if(data[i].type===1 || data[i].type===2 || data[i].type===5) finaldata.push(data[i]);
      }

      notify.show("Saving. Please Wait...", "success",-1);

      let resp = await postMethod("/addslots", {
        data: finaldata,
        tz: new Date().getTimezoneOffset(),
      })
      
      props.reloadSlots();
      setTimeout(
        () => {notify.hide(); notify.show("Schedule Saved Successfully", "success")},
        100
      )
      
    } catch (e) {
      setTimeout(
        () => notify.show("Server Error! Please try again later.", "error"),
        100
      )
    }
  }

  const commitChanges = ({ added, changed, deleted }) => {


    let data = props.data

    const weekday = new Array();
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";

    if (added) {
      if (added.isRepeat) {
        let start = new Date(added.startDate)

        //console.log(added.tillDate);
        let till = moment(`${added.tillDate} 23:59:59`,'yyyy-MM-DD HH:mm:ss');
        let end = new Date(added.endDate)
        //console.log(till);
        let loop = new Date(start)
        let tillDate = new Date(end)
        let startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0
        let arr = []


        while (loop <= till) {
          let obj = {}
          obj["id"] = startingAddedId;
          obj["startDate"] = new Date(loop);
          obj["endDate"] = new Date(tillDate);
          obj["type"] = added.type;

          //console.log(weekday[new Date(loop).getUTCDay()],":::",added.rdays[weekday[new Date(loop).getUTCDay()]])

          let isHoliday = false;

          for(let h=0; h<props.holidays.length; h++){
            if(new Date(props.holidays[h].date + ' 00:00:00')<obj["startDate"] && new Date(props.holidays[h].date+ ' 23:59:59')>obj["endDate"] ){
              isHoliday = true; break;
            }
          }

          if(!isHoliday){
            if(!added.rdays) arr.push(obj);
            else if(added.rdays[weekday[new Date(loop).getUTCDay()]]) arr.push(obj);
          }

          let newDate = loop.setDate(loop.getDate() + 1)
          let newTillDate = tillDate.setDate(tillDate.getDate() + 1)
          loop = new Date(newDate)
          tillDate = new Date(newTillDate)
          startingAddedId++
        }
        data = [...arr]
      } else {

        let isHoliday = false;
          for(let h=0; h<props.holidays.length; h++){
            if(new Date(props.holidays[h].date + ' 00:00:00')<added["startDate"] && new Date(props.holidays[h].date)>added["endDate"] + ' 23:59:59'){
              isHoliday = true; break;
            }
          }
        const startingAddedId =
        data.length > 0 ? data[data.length - 1].id + 1 : 0
        //added.startDate = moment(added.startDate).format('YYYY-MM-DD HH:mm:ss')
        //added.endDate = moment(added.endDate).format('YYYY-MM-DD HH:mm:ss');
        data = [{ id: startingAddedId, ...added }]
      }

      props.setAppointments(data)
      submitSchedule(data)
    }
    if (changed) {


      data.map((appointment) => {


        if (changed[appointment.id]) {
          props.updateSlots(appointment.id, { ...changed[appointment.id] })

        }
      })

    }
    if (deleted !== undefined) {
      //console.log(data);

    
      let deleteddata = data.filter(appointment => appointment.id === deleted)
      
      if(deleteddata[0].type==="4"){
          navigate('/cancel',{state:{booking:deleteddata[0].bookingdata}});
      }else{
        data = data.filter(appointment => appointment.id !== deleted)
        props.setAppointments(data)
        props.removeSlots(deleted, deleteddata);
      }
    }


  }

  const resources = [{
    fieldName: 'type',
    title: 'Types',
    instances: props.data,
  }];


  return (
    <div className="main-sch">
      <Paper>
        <Scheduler data={props.data} timeZone="UTC">
          <ViewState
            currentDate={currentDate}
            currentViewName={currentViewName}
            onCurrentViewNameChange={setCurrentViewName}
            onCurrentDateChange={setCurrentDate}
          />
          <EditingState onCommitChanges={commitChanges} />
          <IntegratedEditing />
          <DayView
            // startDayHour={9}
            // endDayHour={22}
            cellDuration={60}
            showAllDayTitle={true}
          />
          <WeekView
            // startDayHour={9}
            // endDayHour={22}
            cellDuration={60}
            timeTableCellComponent={TimeTableCell}
            dayScaleCellComponent={DayScaleCell}
          />


          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <ViewSwitcher />
          <EditRecurrenceMenu />

          <Appointments appointmentComponent={Appointment}/>
          <AppointmentTooltip
            // showCloseButton
            // showDeleteButton
            // showOpenButton
            visible={false}
          />
          <AppointmentForm basicLayoutComponent={BasicLayout} user={props.user}/>
        </Scheduler>
      </Paper>
    </div>
  )
}

export default BookingSchedule
