import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from '../components/dashboard/class'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { getMethod } from '../helper/api';
import { navigate } from "gatsby";
import { notify } from 'react-notify-toast';

const AllReviews = ({ location, user }) => {

    const [rating, setRating] = useState([]);
    const [feedback, setFeedback] = useState(null);

    useEffect(() => {
        getMyReview()
        async function getMyReview() {

            let sub2 = await getMethod('/reviews', '', {postedFor:  user.authenticate.user.id});
            
            if(sub2.data.length>0){
                
                for(var i=0; i<sub2.data.length; i++){
                    let booking = await getMethod('/bookings', '', {id:  sub2.data[i].booking.id});
                    sub2.data[i].booking = booking.data[0];
                }

                setRating(sub2.data);
                //setFeedback(sub2.data);
            }
        }
    }, [])

    if(!location.state){
        location.state = {}
    }

    return (
        <Layout title="Report Card">
            <Seo title="Report Card" />
            <div className="container">
                <div className="report-section">
                    <div className="row">
                        <div className="col-6 ms-auto">
                            {/* <div className="select-wrapper">
                                <select className="form-control form-control-sm">
                                    <option value="--Select--" key="0">Omar</option>
                                    <option value="Omar">Omar</option>
                                    <option value="Omar">Omar</option>
                                    <option value="Omar">Omar</option>
                                </select>
                            </div>     */}
                        </div>
                    </div>
                    {/* <div className="row gx-3">
                        <div className="col">
                            <div className="card text-center">
                                <div>Total</div>
                                <h3 className="mb-0">14</h3>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card text-center">
                                <div>Completed</div>
                                <h3 className="mb-0 text-success">10</h3>
                            </div>
                        </div>
                    </div> */}
                </div>
                {rating.map((review)=>{
                return (<div className="mb-3">
                    <Booking booking={review.booking} />
                    <div className="row gx-3">
                        <div className="col">
                            <label className="sm">Class Notes</label>
                        </div>
                        {/* <div className="col-auto">
                            <a className="btn btn-sm btn-link">View Recording</a>
                        </div> */}
                    </div>
                    <div className="card card-info">
                        {review.feedback}
                    </div>
                </div>)})}
            </div>    
        </Layout>
    );
}

export default connect(state => ({ bookings: state.userReducer.bookings, user: state.loginReducer }), null)(AllReviews);
