import React from 'react'
import { Cookies } from 'react-cookie';
import { useDispatch} from 'react-redux';
import { loginCheck } from "../../state/actions/login/loginAction"

const Student = props => {

  const dispatch = useDispatch()

  async function setProfile(profile){
    props.setUserSwitch(false);
    let cookies = new Cookies();
    let token = cookies.get('idToken');
    let userAuth = { token: token, profile: profile.id}
    dispatch(loginCheck(userAuth))
  }
  
  if(!props.user){
    props.user = {};
  }

  console.log(props.user);
  return (
    <div className='teacher-card text-center card mb-0' onClick={()=>{setProfile(props.user.user_id)}}>
          <div className="avatar mx-auto mb-2">
            {!props.user.user_id.photo  && <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />}
            {props.user.user_id.photo  && <img src={props.user.user_id.photo} width="50" height="50"/>}
          </div>
          <h6><strong>{props.user.user_id.firstname} {props.user.user_id.lastname}</strong></h6>
          <p className="desc mb-2">{props.user.user_id.email}</p>
    </div>
  )
}

export default Student
