import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"


const AvailableTeacher = () => {

  const dispatch = useDispatch();


  return (
    <Layout>
      <Seo title="Home" />
      <div className="available-section">
        <div className="container">
          <div className="teachers-list">
            <div className="teacher-card card">
              <div className="row gx-3 align-items-center">
                <div className="col-auto">
                  <div className="avatar">
                    <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />
                  </div>
                </div>
                <div className="col">
                  <h6><strong>Fatimah Bahia</strong></h6>
                  <p className="desc mb-0">Senior Tutor</p>
                </div>
                <div className="col-auto d-flex  align-items-center">
                  <i className="icon icon-Star_Fill"></i>
                  <span className="h6 mb-0"><strong>4.8</strong></span>
                </div>
              </div>
            </div>
            <div className="teacher-card card">
              <div className="row gx-3 align-items-center">
                <div className="col-auto">
                  <div className="avatar">
                    <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />
                  </div>
                </div>
                <div className="col">
                  <h6><strong>Fatimah Bahia</strong></h6>
                  <p className="desc mb-0">Senior Tutor</p>
                </div>
                <div className="col-auto d-flex  align-items-center">
                  <i className="icon icon-Star_Fill"></i>
                  <span className="h6 mb-0"><strong>4.8</strong></span>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <div className="fixed-btn-wrapper">
        <div className="row gx-3">
          <div className="col">
            <button className="btn btn-default w-100 mb-0" type="button">One Lesson</button>
          </div>
          <div className="col">
            <button className="btn btn-primary w-100 mb-0" type="button">Recurring Lesson</button>
          </div>
        </div>
      </div>

    </Layout>
  );
}
export default AvailableTeacher;