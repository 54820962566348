import React, { useEffect, useState, useMemo } from 'react'
import DatePickerComponet from '../components/datepicker-component'
import { useDispatch, useSelector, connect } from 'react-redux'
import { Link } from 'gatsby'
import { setBookingDetails } from '../state/actions/booking/bookingAction'

const DatePicker = ({ bookingDetails }, props) => {
  const dispatch = useDispatch()

  const [teacher, setTeacher] = useState('')

  useEffect(() => {}, [])

  return (
    <div>
      <header className='bar d-flex'>
        <Link to='/book-a-lesson'>
          <i
            className='col-auto icon icon-Left p-0'
            style={{ fontSize: 40, fontWeight: 500 }}
          />
        </Link>
        <h5>{bookingDetails.bookingObject.dateType} Lesson</h5>
        <button className='bar-item button float-right ml-auto'>
          <i className='icon icon-Sort' />
        </button>
      </header>
      <div className='container animated fadeInLeft'>
        <div className='card-white border-radius '>
          <div class='row d-block month mx-sm'>
            <p style={{fontWeight:500}}>Select Date & Time</p>
            <div class='row'>
              <DatePickerComponet {...bookingDetails} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  state => ({ bookingDetails: state.bookingReducer }),
  null
)(DatePicker)
