import * as React from "react"
import { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { navigate } from "gatsby"
import Notifications from "react-notify-toast"
import { Link } from "gatsby"
import { getMethod, postMethod } from "../helper/api"
import { getWindowItem } from "../helper/globals"
import { loginFail, loginSuccess } from '../state/actions/login/loginAction';
import { useDispatch, connect } from 'react-redux';
import User from '../components/dashboard/student-card';
import firebase from "gatsby-plugin-firebase"

const Nav = (props, ref) => {
  const dispatch = useDispatch();
  const [showNav, showNavBar] = useState(false)
  const [hideNav, hideNavBar] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [showProfiles, setShowProfiles] = useState(false)
  var [subjects, setSubjects] = useState([])
  const [profiles, setProfiles] = useState([]);

  function delete_cookie(name) {
    document.cookie = name + '=; Path=/dashboard; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  async function Logout() {
    try {
      //if(window?.recaptchaVerifier) window.recaptchaVerifier.clear();
    } catch (e) {

    }
    await delete_cookie('token');
    await delete_cookie('idToken');
    await firebase.auth().signOut();

    if (window.ReactNativeWebView !== undefined) { window.ReactNativeWebView.postMessage("Logout") }
    await dispatch(loginSuccess({ data: null }));
    navigate('/', true);
  }

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */

    function handleClickOutside(event) {
      if (document.getElementById('mySidebar') && !document.getElementById('mySidebar').contains(event.target) && showNav === true) {
        document.removeEventListener("mousedown", handleClickOutside);
        showNavBar(false);
      } else if(document.getElementById('profile-dropdown') && !document.getElementById('profile-dropdown').contains(event.target) && showProfiles === true) {
        document.removeEventListener("mousedown", handleClickOutside);
        setShowProfiles(false);
      }

      if (document.getElementById('profileslist') && !document.getElementById('profileslist').contains(event.target)) {
        //alert("You clicked outside of me!");
        document.removeEventListener("mousedown", handleClickOutside);
        setShowProfiles(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showNav, showProfiles]);

  useEffect(() => {
    async function getSubjects() {
      let sub = await getMethod("/subjects", "", {})
      setSubjects(sub.data)
    }
    getSubjects()
  }, [subjects?.length])

  useEffect(()=>{
    
    //let u = user.authenticate?.user;
    async function getProfiles(){
      let res = await getMethod('/users/me');
      let u = res.data;
      let profiles = await postMethod('/auth/switchprofiles',{t: u.auth_id});
      setProfiles(profiles.data.profiles);
    }

    if(showProfiles){
      getProfiles();
    }
  },[showProfiles])

  async function cancelCart(cart) {
    let sub = await postMethod("/abortcart", { cart_id: cart.id }, {});
    window.localStorage.removeItem('cart');
    //setSubjects(sub.data)
  }

  async function discardChanges() {
    if (getWindowItem('cart', false)) {
      if (window.confirm("Are you sure to discard the booking?")) {
        await cancelCart(JSON.parse(getWindowItem('cart')));
        navigate('/dashboard?booking=success');
      } else {
        //navigate(-1);
      }
    } else {
      navigate(-1);
    }
  }

  return (
    <>
      {showNav && (<div onClick={() => { hideNavBar(!hideNav) }} className="nav-overlay" />)}
      <nav className="navbar">
        <button
          className="navbar-toggler"
          id="menu-icon"
          onClick={() => {
            if (props.title === "Cart")
              discardChanges();
            else {
              props.title === "Dashboard" ? showNavBar(!showNav) : navigate(-1)
            }
          }}
        >
          {props.title === "Dashboard" ? (
            <i className="icon icon-Hamburger" />
          ) : (
            <i className="icon icon-Left" />
          )}
        </button>
        <h5 className="mb-0 me-auto" style={{ padding: 0 }}>
          {props.title}
        </h5>

        {props.user?.user_role=='faculty' ? (
          // <div className="holiday ml-auto">
          //   <button className="bar-item button float-right">
          //     <i className="icon icon-Notification"></i>
          //     <span className="badge sup-notification"></span>
          //   </button>
          //   <Link to="/add-holidays">
          //     <button className="bar-item button float-right">
          //       <i className="icon icon-add"></i>
          //     </button>
          //   </Link>
          // </div>
          <></>
        ) : (
          <>
          {/* <button className="btn ms-auto">
            <span className="nav-notification">
              <i className="icon icon-Notification"></i>
              <span className="notification-on"></span>
            </span>
          </button> */}
          {props.user?.firstname && (<button className="btn" onClick={()=>setShowProfiles(!showProfiles)}>
            <span className="nav-notification" style={{fontSize:'14px'}}>
              {!props?.user?.photo && <i className="icon icon-Profile" style={{verticalAlign:"bottom"}}></i>}
              {props?.user?.photo && <img src={props?.user?.photo} width="20" height="20"></img>}
              {`${props?.user?.firstname}`}
            </span>
          </button>)}
          <div id="profileslist" style={{position:"absolute", backgroundColor:"aliceblue", width:"300px", zIndex:1000,right:0, top:"50px", paddingBottom:"10px", display:`${showProfiles?"":"none"}`}}>
            {profiles.map((user, i) => {
              return (<div key={i} className='container animated fadeInLeft' style={{ width: "300px", marginTop:"10px" }}>
                <div className='card-white border-radius class-list mx-sm'>
                  <User user={user} setShowProfiles={setShowProfiles}/>
                </div>
                </div>
                )
              })}
          </div>
          </>)}
        <Notifications
          options={{ zIndex: 200, top: "40px", position: "relative" }}
        />
      
      <div className={showNav === true ? "side-nav is-open" : "side-nav"} id="mySidebar">
        <div
          className="profile-block d-flex align-items-center"
        >
          <div className="avatar rounded-circle">
            {!props.user?.photo && <i
              className="icon-Profile icon"
            />}
            {props.user?.photo && <img
              src={props.user.photo} width="75" height="75"
            />}
          </div>
          <div className="profile-details">
            <h6 className="mb-0">Welcome</h6>
            <h5 className="mb-0">
              {props.user?.firstname}
            </h5>
            {props.user?.user_role==='student' && <div><Link to="/profiles" state={{switch:true}}>Switch User</Link></div>}
          </div>
        </div>
        <ul className="nav flex-column">
          <li className="nav-item" onClick={() => { hideNavBar(!hideNav) }}>
            <Link className="nav-link" to="/dashboard">
              <i className="icon icon-Dashboard" />
              <span className="pl-sm">Dashboard</span>
            </Link>
          </li>
          {props.user?.user_role === 'student' && <li className="nav-item">
            <Link
              className="nav-link"
              to="/book-a-lesson"
              state={{ subjects: subjects }}
            >
              <i className="icon icon-Class" />
              <span className="pl-sm">Book a Lesson</span>
            </Link>
          </li>}
          {props.user?.user_role === 'faculty' && <><li className="nav-item">
            <Link
              className="nav-link"
              to="/teacher-schedule"
              state={{ subjects: subjects }}
            >
              <i className="icon icon-Class" />
              <span className="pl-sm">Set Schedules</span>
            </Link>
          </li>
            {/* <li className="nav-item">

              <Link
                className="nav-link"
                to="/teacher-schedule" to="/add-holidays">
                <i className="icon icon-add"></i>
                <span className="pl-sm">Holidays</span>
              </Link>
            </li> */}
            <li className="nav-item">

              <Link
                className="nav-link"
                to="/teacher-schedule" to="/preferences">
                <i className="icon icon-add"></i>
                <span className="pl-sm">My Level</span>
              </Link>
            </li></>
          }
          <li className="nav-item">
            <Link className="nav-link" to="/my-classes">
              <i className="icon icon-Lesson" />
              <span className="pl-sm">My Lessons</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/profile">
              <i className="icon icon-Profile" />
              <span className="pl-sm">Profile</span>
            </Link>
          </li>
          {/* <li className="nav-item">
          <a className="nav-link" href="#" onClick={Logout}>
            <i className="icon icon-Logout" />
            <span className="pl-sm">Logout</span>
          </a>
        </li> */}
          <li className="nav-item" id="moreMenu-toggler">
            <a
              className="nav-link d-flex"
              href="#"
              onClick={() => setShowMore(!showMore)}
            >
              <i className="icon icon-More" />
              <span className="pl-sm">More</span>
              <div id="moreMenu-arrow" style={{ marginLeft: "auto" }}>
                <i className="icon icon-Down" />
              </div>
            </a>
          </li>
          {showMore && (
            <ul className="nav flex-column">
              <li className="nav-item">
                <Link className="nav-link" to="/about-us">
                  <i className="icon icon-Info" />
                  <span className="pl-sm">About Us</span>
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="icon icon-Like" />
                  <span className="pl-sm">Rate the App</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="icon icon-Help" />
                  <span className="pl-sm">FAQ</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="icon icon-Contact-us" />
                  <span className="pl-sm">Contact Us</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="icon icon-Terms-Policy" />
                  <span className="pl-sm">T&amp;C</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" onClick={Logout}>
                  <i className="icon icon-Logout" />
                  <span className="pl-sm">Logout</span>
                </a>
              </li>
            </ul>
          )}
        </ul>
      </div>
     </nav>
    </>
  )
}

export default Nav