import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, connect, useReducer } from 'react-redux'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from './layout'
import Seo from './seo'
import Nav from './nav'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Formik, Field, useFormikContext } from 'formik'
import { updateProfile } from '../state/actions/login/loginAction'
import { navigate } from 'gatsby';
import { setBookingDetails } from '../state/actions/booking/bookingAction';

const Datepicker = ( props) => {

  const [dateType, getDateType] = useState(props.bookingObject.dateType ? props.bookingObject.dateType : 'single' );
  
  const [fromDate, setStartDate] = useState(new Date());
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch();

  useEffect(() => {
   
  }, [])

 const setSelectedDate = (update) =>{
  

  if(dateType === 'recurring'){
    setDateRange(update)
    const date ={
      'startDate': moment(update[0]).format('yyyy-MM-DD'),
      'endDate':moment(update[1]).format('yyyy-MM-DD')
    }
    dispatch(setBookingDetails({date:date}))
    if(update[1] !== null){
     // navigate('/bookingSlots')
    }
   
  }
  if(dateType === 'single'){
    setStartDate(update)
    let date ={
      'startDate':moment(update).format('yyyy-MM-DD')
    }
    dispatch(setBookingDetails({date:date}));
    //navigate('/bookingSlots')
  }
 
 }

  return (
<>
        { (dateType === 'recurring') && (
         <DatePicker
         selectsRange={true}
         startDate={startDate}
         endDate={endDate}
         onChange={(update) => {
          setSelectedDate(update);
         }}
         isClearable={true}
       />
        )}
        
        { (dateType === 'single') && (
      
        <DatePicker selected={fromDate} onChange={(date) => setSelectedDate(date)} />


        )}
        
    </>
  )
}

export default Datepicker;
